<template>
    <el-dialog
        class="editDialog"
        :append-to-body="false"
        :modal="false"
        :fullscreen="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"

    >

        <div class="content" v-loading="loading">
            <el-form label-width="0" :model="form" :rules="rules" ref="form" size="mini">
                <el-descriptions  :column="4"  border>

                    <el-descriptions-item>
                        <template slot="label">表名</template>
                        <el-form-item prop="tableName" ref="tableName">
                            <ChoiceData :configure="{request:$api['admin/metadataTable'].findPage,type:'radio'}" @choiceDataChange="choiceDataChange">
                                <el-input slot="button" :readonly="true"  placeholder="请选择表名" v-model="form.tableName"></el-input>
                                    <template slot="tableColumn"">
                                        <el-table-column align="center" prop="tableName" label="表名"></el-table-column>
                                        <el-table-column prop="tableNote" align="center" label="备注"></el-table-column>
                                    </template>
                                </ChoiceData>
                        </el-form-item>
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">表别名</template>
                        <el-form-item prop="tableLabel" ref="tableLabel">
                            <el-input v-model="form.tableLabel" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                        </el-form-item>
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">表单每行展示</template>
                        <el-form-item prop="tableColumn" ref="tableColumn">
                            <el-select v-model="form.tableColumn" placeholder="请选择">
                                <el-option label="1列" value="column1"></el-option>
                                <el-option label="2列" value="column2"></el-option>
                                <el-option label="3列" value="column3"></el-option>
                                <el-option label="4列" value="column4"></el-option>
                                <el-option label="5列" value="column5"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">表单是否弹窗显示</template>
                        <el-form-item prop="fullScreen" ref="fullScreen">
                            <el-switch
                                active-value="true"
                                inactive-value="false"
                                v-model="form.fullScreen"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                            </el-switch>
                        </el-form-item>
                    </el-descriptions-item>

                </el-descriptions>
                <el-table ref="formTable" :data="form.fields" height="string" border  header-cell-class-name="formTableHeader">

                    <el-table-column label="字段名" align="center" width="120">
                        <template slot-scope="scope">
                            <el-form-item :prop="'fields[' + scope.$index + '].fieldName'" ref="fieldName" :rules="rules.fieldName">
                                <el-select v-model="scope.row.fieldName" placeholder="请选择" @change="fieldNameChange($event,scope)">
                                    <template v-for="item in fieldOptions">
                                        <el-option v-if="!(form.fields.some(r=>{return r.fieldName==item.fieldName}))" :key="item.id" :label="item.fieldName" :value="item.fieldName">
                                            <span style="float: left">{{ item.fieldName }}</span>
                                            <span style="float: right; color: #8492a6; font-size: 13px;margin-left:20px;">{{ item.fieldLabel }}</span>
                                        </el-option>
                                    </template>
                                </el-select>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column label="标题" align="center">
                        <template slot-scope="scope">
                            <el-form-item  :prop="'fields[' + scope.$index + '].fieldLabel'" ref="fieldLabel">
                                <el-input v-model="scope.row.fieldLabel" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column label="分组名称" align="center">
                        <template slot-scope="scope">
                            <el-form-item  :prop="'fields[' + scope.$index + '].groupName'" ref="groupName">
                                <el-input v-model="scope.row.groupName" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>


                    <el-table-column label="列表显示" align="center" width="80">
                        <template slot-scope="scope">
                            <el-switch
                                active-value="true"
                                inactive-value="false"
                                v-model="scope.row.listIsShow"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                            </el-switch>
                        </template>
                    </el-table-column>

                    <el-table-column label="列表排序" align="center" width="80">
                        <template slot-scope="scope">
                            <el-switch
                                active-value="custom"
                                inactive-value="false"
                                v-model="scope.row.sortable"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                            </el-switch>
                        </template>
                    </el-table-column>

                    <el-table-column label="列表搜索" align="center" width="80">
                        <template slot-scope="scope">
                            <el-switch
                                active-value="true"
                                inactive-value="false"
                                v-model="scope.row.search"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                            </el-switch>
                        </template>
                    </el-table-column>

                    <el-table-column label="表单显示" align="center" width="80">
                        <template slot-scope="scope">
                            <el-switch
                                active-value="true"
                                inactive-value="false"
                                v-model="scope.row.formIsShow"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                            </el-switch>
                        </template>
                    </el-table-column>

                    <el-table-column label="表单项宽度(%)" align="center">
                        <template slot-scope="scope">
                            <el-form-item  :prop="'fields[' + scope.$index + '].itemWidth'" ref="itemWidth">
                                <el-input-number v-model="scope.row.itemWidth" :precision="2" :step="0.01" :max="100"></el-input-number>
                           </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column label="标题宽度" align="center">
                        <template slot-scope="scope">
                            <el-form-item  :prop="'fields[' + scope.$index + '].labelWidth'" ref="labelWidth">
                                <el-input v-model="scope.row.labelWidth" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column label="禁止编辑" align="center" width="80">
                        <template slot-scope="scope">
                            <el-switch
                                active-value="true"
                                inactive-value="false"
                                v-model="scope.row.isEdit"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                            </el-switch>
                        </template>
                    </el-table-column>

                    <el-table-column label="是否必填" align="center"  width="80">
                        <template slot-scope="scope">
                            <el-switch
                                active-value="true"
                                inactive-value="false"
                                v-model="scope.row.required"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                            </el-switch>
                        </template>
                    </el-table-column>

                    <el-table-column label="验证规则" align="center"  width="80">
                        <template slot-scope="scope">

                            <ValidationRules v-model="scope.row.rules" :title="`${scope.row.fieldLabel||scope.row.fieldName||''}验证规则`">
                                <el-input readonly size="mini" :value="`${scope.row.rules?scope.row.rules:''}`"></el-input>
                            </ValidationRules>

                        </template>
                    </el-table-column>

                    <el-table-column label="控件类型" align="center">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.fType" placeholder="请选择" size="mini">
                                <el-option label="输入框" value="text"></el-option>
                                <el-option label="文本框" value="textarea"></el-option>
                                <el-option label="计数器" value="inputNumber"></el-option>
                                <el-option label="数据字典" value="dictionaries"></el-option>
                                <el-option label="日期" value="date"></el-option>
                                <el-option label="时间" value="time"></el-option>
                                <el-option label="日期加时间" value="dateTime"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>

                    <!--controlValueDomain-->
                    <el-table-column label="控件值域" align="center">
                        <template slot-scope="scope">
                          <el-form-item>
                            <template v-if="scope.row.fType=='dictionaries'">
                                <ControlValueDomain v-model="scope.row.controlValueDomain" :type="scope.row.fType" :title="`${scope.row.fieldLabel||scope.row.fieldName||''}控件值域`">
                                    <el-input readonly size="mini" :value="`${scope.row.controlValueDomain?scope.row.controlValueDomain:''}`"></el-input>
                                </ControlValueDomain>
                            </template>
                            <template v-else>
                                <el-input :disabled="true" size="mini"></el-input>
                            </template>
                            </el-form-item>
                        </template>
                    </el-table-column>


                    <el-table-column align="center" width="80" fixed="right">
                        <el-button slot="header" type="primary" size="mini" icon="el-icon-plus" @click="add"></el-button>
                        <template slot-scope="scope">
                            <el-button slot="header" type="danger" size="mini" icon="el-icon-delete" @click="del(scope)"></el-button>
                        </template>
                    </el-table-column>

                </el-table>

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
<!--            <el-button type="success" :loading="submitLoading" @click="cc">保存</el-button>-->
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

import mixinsForm from '@/mixins/form'
import Sortable from "sortablejs";
import {deleteField} from "@/api/controller/admin/formConfig";
export default {
    components: {
        Dictionary: () => import("@/components/Dictionary.vue"),
        ChoiceData: () => import("@/components/ChoiceData.vue"),
        ValidationRules: () => import("./ValidationRules.vue"),
        ControlValueDomain: () => import("./ControlValueDomain.vue"),
    },
    mixins: [mixinsForm],
    data() {
        return {
            elFormTable:null,
            fieldOptions:[],
            form: {
                fields:[],
            },
            rules: {
                tableName: [{ required: true, message: '请输入表名', trigger:['blur','change'] }],
                tableLabel: [{ required: true, message: '请输入表别名', trigger:['blur','change'] }],
                fieldType: [{ required: true, message: '请输入字段类型', trigger:['blur','change'] }],
                fieldName:[
                    { required: true, message: '请选择字段名', trigger: ['blur','change'] },
                    {
                        trigger: ['change', 'blur'], validator: (rule, value, callback) => {
                            var arr = [];
                            this.form.fields.some(r=>{
                                arr.push(r.fieldName);
                            });
                            for(let i = arr.indexOf(value)+1;i<arr.length;i++){
                                if(arr[i]==value){
                                    return callback(new Error('字段名称不能重复'));
                                }
                            }
                            if(!this.fieldOptions.some(r=>{
                                return  value == r.fieldName
                            })){
                                return callback(new Error('字段名错误'));
                            }
                            callback();
                        }
                    }
                ],
            },
        };
    },
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                this.showEditDialog = newVal;
                if (newVal) {
                    this.$nextTick(() => {

                        if(!this.el){
                            this.elFormTable = this.$refs.formTable.$el.querySelector('.el-table__body-wrapper tbody');
                            new Sortable(this.elFormTable, {
                                animation: 150,
                                ghostClass: '.el-table__row',
                                onEnd:r=> {
                                    var currRow = this.form.fields.splice(r.oldIndex, 1)[0];
                                    this.form.fields.splice(r.newIndex, 0, currRow);
                                    var fields = this.form.fields
                                    this.form.fields = []
                                    this.$nextTick(() => {
                                        this.form.fields = fields
                                    })
                                }
                            });
                        }
                    });
                }
            }
        },
        'form.tableName'(res){
            if(res){
                this.$api['survey/receiveCommon'].findByTableName(res).then( res => {
                    this.fieldOptions = res.data.fields;
                }).catch(err=>{
                    console.log(err);
                })
            }else {
                this.fieldOptions = []
            }
        }
    },
    created() {},
    mounted(){},
    methods: {
        init(options){
            if(options.id){
                this.loading = true;
                this.$api['admin/formConfig'].getField(options.id).then( res => {
                    this.form = res.data.info.combination({fields:res.data.list});
                    this.verifyFieldName();
                    this.loading = false;
                }).catch(err=>{
                    console.log(err);
                })
            }else {
                this.form = options.deepClone();
                this.loading = false;
            }
        },
        choiceDataChange(res){
            this.$set(this.form, 'tableName', res.tableName);
        },
        fieldNameChange(a,b){console.log(a)
            var fieldLabel = '';
            this.fieldOptions.some(r=>{
                if(a==r.fieldName){
                    fieldLabel =  r.fieldLabel
                }
            })
            this.form.fields[b.$index].fieldLabel = fieldLabel;
            this.$set(this.form.fields, b.$index, this.form.fields[b.$index]);
        },
        verifyFieldName(){
            for(let i=0;i<this.form.fields.length;i++){
                this.$refs.form.validateField(`fields[${i}].fieldName`);
            }
        },
        add(){
            this.form.fields.push({
                fType:'text',
                listIsShow:'true',
                sortable:'false',
                formIsShow:'true',
                isEdit:'false',
                required:'false',
                fieldName:'',
                fieldLabel:'',
            })
        },
        del(scope){

            if(scope.row.id){
                this.$api['admin/formConfig'].deleteField({ids:[scope.row.id]}).then( res => {
                    this.form.fields.splice(scope.$index,1);
                }).catch(err=>{
                    console.log(err);
                })
            }else {
                this.form.fields.splice(scope.$index,1);
            }

        },
        submit(){

            this.$refs.form.validate((valid,obj) => {
                if (valid) {

                    var cond = this.form.deepClone();
                    cond.labelWidth = 0;
                    //cond.fields = JSON.stringify(this.form.fields);

                    for(let i=0;i<cond.fields.length;i++){
                        let item = cond.fields[i];

                        item.sort = i;

                        if(item.fieldLabel){
                            if(item.fieldLabel.length>cond.labelWidth){
                                cond.labelWidth = item.fieldLabel.length;
                            }
                        }else {
                            if(item.fieldName.length>cond.labelWidth){
                                cond.labelWidth = item.fieldName.length;
                            }
                        }
                    }


                    this.submitLoading = true;

                    this.$api['admin/formConfig'].save(cond).then( res => {
                        this.submitLoading = false;
                        this.refresh();
                        this.$message.success("操作成功！");

                    }).catch(err=>{
                        this.submitLoading = false;
                        console.log(err);
                    })
                }else {
                    this.scrollView(obj)
                };
            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.el-form {
    display:block;
    height:100%;
    /deep/.el-form-item {
        margin:0;
        padding:0;
        &.is-error {
            position:relative;
            bottom:6px;
            .el-input__inner {
                border-color:#f00;
            }
            .el-form-item__error {

                transform: scale(1,.8);
            }
        }
        .el-form-item__content {
            line-height: 28px;
        }
    }
    /deep/.el-descriptions {
        white-space: nowrap;
        td,th {
            text-align:center;
        }
    }
    /deep/.el-table {
        height:calc(100% - 55px);
        .formTableHeader {
            background: #fafafa!important;
        }
    }
}
</style>
